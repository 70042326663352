// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-and-always-forgets-his-business-cards-js": () => import("./../../../src/pages/and-always-forgets-his-business-cards.js" /* webpackChunkName: "component---src-pages-and-always-forgets-his-business-cards-js" */),
  "component---src-pages-and-owns-a-suit-js": () => import("./../../../src/pages/and-owns-a-suit.js" /* webpackChunkName: "component---src-pages-and-owns-a-suit-js" */),
  "component---src-pages-and-writes-js": () => import("./../../../src/pages/and-writes.js" /* webpackChunkName: "component---src-pages-and-writes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-template-blog-post-single-js": () => import("./../../../src/template/blog-post-single.js" /* webpackChunkName: "component---src-template-blog-post-single-js" */)
}

